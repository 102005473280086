import React, { useState, useEffect } from "react"
import { navigate, graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import axios from "axios"

import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import { mutationEdit, getUser, setUser, isLoggedIn } from "../services/auth"
import { isBrowser } from "../helpers"
import PersonIcon from "@material-ui/icons/Person"

const styles = theme => ({
  accountLink: {
    display: "inline-block",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    lineHeight: "20px",
    marginBottom: 5,
    textDecoration: "none",
    padding: "5px 20px",
    "& svg": {
      width: 18,
      height: 18,
      verticalAlign: "top",
      marginRight: 5,
    },
  },
})

const useStyles = makeStyles(theme => styles(theme))

export default function Login({ data }) {
  const classes = useStyles()

  useEffect(() => {
    checkIfLoggedIn()
  }, [data])

  async function checkIfLoggedIn() {
    const response = await isLoggedIn()
    if (response.data.errors) {
      navigate("/login")
    }
  }

  const currentDetails = getUser()
  const [forename, setForename] = useState(currentDetails.firstName)
  const [lastname, setLastname] = useState(currentDetails.lastName)
  const [email, setEmail] = useState(currentDetails.login)
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [processing, setProcessing] = useState(false)

  async function handleSubmit(e) {
    if (e) e.preventDefault()

    if (!isBrowser) return false

    setProcessing(true)

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        mutationEdit({
          id: currentDetails.id,
          firstname: forename,
          lastname,
          email,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentDetails.token}`,
          },
        }
      )
      .then(res => {
        if (res.data.errors) {
          const { message } = res.data.errors[0]
          if (
            message === "incorrect_password" ||
            message === "invalid_username" ||
            "Internal server error"
          ) {
            setError("Incorrect login details")
            setProcessing(false)
          }

          return null
        }

        setUser({
          ...currentDetails,
          firstName: forename,
          lastName: lastname,
        })

        setError("")
        setSuccess(true)
        setProcessing(false)
      })
  }

  return (
    <Layout meta={{}} path={"a"} cta={"a"} themeOptions={data.wp.themeOptions}>
      <Breadcrumb
        type="page"
        current={{
          title: "Edit",
          slug: "edit",
          uri: "/edit",
        }}
      />
      {processing && <div>Updating your profile...</div>}
      {success && (
        <div>
          <h2>Thank you</h2>
          <p>Your details have been updated successfully.</p>
          <Link to="/account/" className={classes.accountLink}>
            <PersonIcon /> My Account
          </Link>
        </div>
      )}
      {!!error && <div>{error}</div>}
      {!success && (
        <form
          onSubmit={handleSubmit}
          style={{
            backgroundColor: "#1b365d",
            margin: "50px auto",
            maxWidth: 768,
            padding: 32,
          }}
        >
          <h3
            style={{
              color: "white",
              fontWeight: "normal",
              letterSpacing: "0.016em",
              fontSize: 32,
              marginTop: 0,
              marginBottom: 12,
            }}
          >
            Edit your details
          </h3>
          <p
            style={{
              color: "white",
            }}
          >
            Please make any changes you need to your details below.
          </p>
          <label
            htmlFor="forename"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            First Name:
          </label>
          <input
            name="forename"
            type="text"
            placeholder="Your first name"
            value={forename}
            onChange={e => setForename(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          <label
            htmlFor="lastname"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            Last Name:
          </label>
          <input
            name="lastname"
            type="text"
            placeholder="Your last name"
            value={lastname}
            onChange={e => setLastname(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          <label
            htmlFor="email"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            Email Address
          </label>
          <input
            name="email"
            type="email"
            placeholder="Email address"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          <label
            htmlFor="password"
            style={{
              color: "white",
              fontSize: 12,
              lineHeight: 1.6,
              letterSpacing: "0.0152em",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Overpass",
            }}
          >
            Password
          </label>
          <input
            name="password"
            type="password"
            placeholder="Password"
            required
            onChange={e => setPassword(e.target.value)}
            style={{
              fontFamily: "Overpass",
              backgroundColor: "transparent",
              borderRadius: 20,
              fontSize: 12,
              lineHeight: 2,
              padding: "7px 20px",
              border: "1px solid white",
              marginBottom: 24,
              color: "white",
              outline: "none",
              display: "block",
              width: "100%",
            }}
          />
          <input
            type="submit"
            value="Submit"
            style={{
              width: "auto",
              backgroundColor: "#c8102e",
              color: "white",
              fontFamily: "Bebas Neue",
              fontSize: 16,
              display: "block",
              padding: "14px 40px",
              letterSpacing: "0.016em",
              textAlign: "center",
              backgroundPosition: "center right 24px",
              backgroundRepeat: "no-repeat",
              outline: "none",
              boxShadow: "0 8px 10px rgb(0 0 0 0.8)",
              cursor: "pointer",
              borderRadius: 32,
              border: "none",
            }}
          />
        </form>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
